import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/Template.js";
import AnalyticsScript from 'src/components/Analytics';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contact`}</h1>
    <hr></hr>
    <h2>{`Email`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "mailto:contact@memeinsider.com"
        }}>{`contact@memeinsider.com`}</a></em></p>
    <h2>{`Twitter`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://twitter.com/memeinsider"
        }}>{`@MemeInsider`}</a></em></p>
    <h2>{`Instagram`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.instagram.com/memeinsider/"
        }}>{`@MemeInsider`}</a></em></p>
    <h2>{`Youtube`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.youtube.com/channel/UC2RAJCLDzNkQqS29OKitO_Q"
        }}>{`Meme Insider`}</a></em></p>
    <AnalyticsScript mdxType="AnalyticsScript" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      